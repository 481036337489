import {
  Avatar,
  Card,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./OtherTradesman.css";

function OtherTradesman() {

    // hooks
    const {propertyId} = useParams(); 
    const navigate = useNavigate(); 

    // state variables 
    const [state, setState] = React.useState({
        loading: false, 
        tradesman: [], 
    })


    React.useEffect(() => {
        async function handleFetch () {
            const docRef = doc(db, 'properties', propertyId); 
            const docData = await getDoc(docRef); 

            if (docData.exists) {

                const tradies = [...docData.data().tradesman]; 
                const extraData = await Promise.all(tradies.map( async (tradie) => {
                    const tradieRef = doc(db, 'Tradesman', tradie); 
                    const tradieData = await getDoc(tradieRef);
                    console.log(tradie, tradieData.data())
                    
                    if (tradieData.exists) {
                        return({...tradieData.data(), id: tradieData.id})
                    } else {
                        return; 
                    }
                }))

                console.log(extraData)




                setState((state) => ({
                    ...state, 
                    tradesman: extraData
                }))

            } else {
                return;
            }

        }

        handleFetch(); 
      
    }, [propertyId])
    
    
    return (
    <Card className="OtherTradesman">
      <div className="title">Other preferred Tradesman</div>

      <div className="list">

        {state.tradesman.map((tradesman) => (

          
        <ListItem onClick={((e) => {navigate(`/tradesman_profile/${tradesman.id}`)})} button dense key={tradesman.id}>
          <ListItemAvatar>
            <Avatar src={tradesman.logo} alt="logo" />
          </ListItemAvatar>{" "}
          <ListItemText
            primary={tradesman.public_name}
            secondary={`${tradesman.address_city}`}
          />
        </ListItem>  
        ))}


      </div>
    </Card>
  );
}

export default OtherTradesman;
