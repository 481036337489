import { Card } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./JobDescription.css";

function JobDescription() {

    // hooks
    const {jobId} = useParams(); 

    // state variables
    const [state, setState] = React.useState({
        desc: '',
    })

    // useEffectFetch Call 
    React.useEffect(() => {
        async function handleFetchCall () {
          const docRef = doc(db, 'job_requests', jobId); 
          const docData = await getDoc(docRef); 
  
          if (docData.exists) {

            console.log(typeof docData.data().completed_at)
              setState((state) => ({
                  ...state, 
                  desc: docData.data().job_description 
              }))
  
          } else {
              return;
          }
  
        }
  
        handleFetchCall(); 
      }, [jobId])
    




  return (
    <Card className="JobDescription">
      <div className="title">Description</div>

      <div className="text">
        {state.desc}
      </div>
    </Card>
  );
}

export default JobDescription;
