import { Home, Logout, Menu, Settings } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../firebase";
import tenacklogo from "../Images/tenacklogo.png";
import "./Header.css";

function Header() {
  // hooks
  const navigate = useNavigate();


  // state variables
  const [state, setState] = React.useState({
    open: false,
  });

  // function handle logout
  function handleLogout() {
    logout();
  }

  const pathes = [
    { title: "Home", icon: <Home style={{ color: "white" }}  />, location:`/home`},
    { title: "Settings", icon: <Settings style={{ color: "white" }}  />, location:`/settings`},
  ];

  return (
    <>
      <div className="Header">
        <div className="left">
          <img
            className="logo"
            src={tenacklogo}
            alt="tenack logo"
            onClick={(e) => {
              navigate("/home");
            }}
          />
        </div>
        <div className="right">
          <IconButton
            onClick={(e) => {
              setState((state) => ({
                ...state,
                open: !state.open,
              }));
            }}
          >
            <Menu style={{ color: "rgb(18, 100, 100)" }} />
          </IconButton>
        </div>

        <div className={state.open ? "nav-menu" : "nav-menu-closed"}>

            {pathes.map((path, ind) => (

                   <div
                   key={ind}
            className="logout"
            onClick={(e) => {
                navigate(path.location)
                setState((state) => ({
                    ...state,
                    open: !state.open,
                  }));
            }}
          >
            <div className="icon">
              {path.icon}
            </div>
            <div className="text">{path.title}</div>
          </div> 
            ))}

<div
            className="logout"
            onClick={(e) => {
              handleLogout()
              console.log('log out')
            }}
          >
            <div className="icon">
              <Logout />
            </div>
            <div className="text">Log out</div>
          </div> 

      


        </div>
      </div>
    </>
  );
}

export default Header;
