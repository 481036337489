import { PushPin } from "@mui/icons-material";
import { Card, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./AssetHistory.css";

function AssetHistory() {
  // hooks
  const { propertyId, assetId } = useParams();
  const navigate = useNavigate(); 

  // state variables
  const [state, setState] = React.useState({
    events: [],
  });


  React.useEffect(() => {
    async function handleFetch () {
      const q = collection(db, 'properties', propertyId, 'assets', assetId, 'asset_events'); 
      const docsData = await getDocs(q); 

      if (docsData.empty) {
        return; 
      } else {
        setState((state) => ({
          ...state, 
          events: docsData.docs.map((doc) => ({...doc.data(), id: doc.id}))
        }))
      }

    }
    handleFetch ()

  }, [propertyId, assetId])
  

  return (
    <Card className="AssetHistory">
      <div className="title">History</div>

      <div className="list">
        {state.events.length === 0 && (
          <div className="noData">
            <div className="message">No Visible Asset Events.</div>
          </div>
        )}

        {state.events.map((event) => (
          <ListItem button key={event.id} onClick={((e) => {navigate(`/properties/${propertyId}/assets/${assetId}/events/${event.id}`)})}> 
            <ListItemIcon style={{color:'rgb(18, 100, 100)'}}>
              <PushPin />
            </ListItemIcon>
            <ListItemText primary={event.title}/>
          </ListItem>
        ))}



      </div>
    </Card>
  );
}

export default AssetHistory;
