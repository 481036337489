import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import "./AccountDetails.css";
import { auth } from "../../../../firebase";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function AccountDetails() {
  const [state, setState] = React.useState({
    loading: false,
    errorOpen: false,
    errorMessage: "",
    successOpen: false,
    currentPassword: '',  
    successMessage: "",
    password: "",
    confirmPassword: "",
    currentVisibility: false, 
    newVisibility: false, 
    confirmVisibility: false
    
  });

  async function handlePasswordChange() {
    setState((state) => ({
      ...state,
      loading: true,
    }));

    if (state.password === state.confirmPassword) {
      if (state.password.length < 8) {
        setState((state) => ({
          ...state,
          errorOpen: true,
          errorMessage: `Oops... Looks like your passwords isn't long enough!`,
          successMessage: "",
          successOpen: false,
          loading: false,
        }));
      } else {
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(
          user.email,
          state.oldPassword
        );

        await reauthenticateWithCredential(user, credential)
          .then((res) => {
            updatePassword(user, state.password)
              .then((res) => {
                setState((state) => ({
                  ...state,
                  errorOpen: false,
                  errorMessage: ``,
                  successMessage: "Successfully Updated Password!",
                  successOpen: true,
                  loading: false,
                  oldPassword: "",
                  password: "",
                  confirmPassword: "",
                }));
              })
              .catch((err) => {
                console.log(err);
                setState((state) => ({
                  ...state,
                  errorOpen: true,
                  errorMessage: `Oops... Looks like something went wrong... please try again`,
                  successMessage: "",
                  successOpen: false,
                  loading: false,
                  password: "",
                  confirmPassword: "",
                }));
              });
          })
          .catch((err) => {
            console.log(err);
            setState((state) => ({
              ...state,
              errorOpen: true,
              errorMessage: `Oops... Looks like your password was incorrect...`,
              successMessage: "",
              successOpen: false,
              loading: false,
            }));
          });
      }
    } else {
      setState((state) => ({
        ...state,
        errorOpen: true,
        errorMessage: `Oops... Looks like your passwords don't match!`,
        successMessage: "",
        successOpen: false,
        loading: false,
      }));
    }
  }

  /* Handle Error and Success SnackBar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (
    <div className="AccountDetails">
      <Snackbar
        open={state.successOpen}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <div className="resetPassword">
        {state.loading && (
          <div className="loading">
            {" "}
            <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
          </div>
        )}

        <div className="title">Reset Password</div>
        <div className="field">
          <TextField
            value={state.oldPassword}
            onChange={(e) => {
              setState((state) => ({ ...state, oldPassword: e.target.value }));
            }}
            type={state.currentVisibility ? 'text' :'password'}
            label="Current Password"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => {
                    setState((state) => ({
                        ...state, 
                        currentVisibility: !state.currentVisibility
                    }))
                    }}
                    style={{ color: "rgb(18, 100, 100)" }}
                  >
                    {state.currentVisibility ? <VisibilityOff/> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="field">
          <TextField
            value={state.password}
            onChange={(e) => {
              setState((state) => ({ ...state, password: e.target.value }));
            }}
            label="New Password"
            fullWidth
            type={state.newVisibility ? 'text' :'password'}
            InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(e) => {
                                setState((state) => ({
                                    ...state, 
                                    newVisibility: !state.newVisibility
                                }))
                                }}
                                style={{ color: "rgb(18, 100, 100)" }}
                              >
                                {state.newVisibility ? <VisibilityOff/> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
          />
        </div>
        <div className="field">
          <TextField
            value={state.confirmPassword}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                confirmPassword: e.target.value,
              }));
            }}
            label="Confirm New Password"
            fullWidth
            type={state.confirmVisibility ? 'text' :'password'}
            InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(e) => {
                                setState((state) => ({
                                    ...state, 
                                    confirmVisibility: !state.confirmVisibility
                                }))
                                }}
                                style={{ color: "rgb(18, 100, 100)" }}
                              >
                                {state.confirmVisibility ? <VisibilityOff/> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
          />
        </div>

        <div className="buttons">
          <Button
            onClick={(e) => {
              handlePasswordChange();
            }}
            style={{
              color: "white",
              backgroundColor: "rgb(18, 100, 100)",
              fontWeight: "bold",
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AccountDetails;
