import {
  ArrowBackIos,
  House,
  MeetingRoom,
} from "@mui/icons-material";
import { Fab, IconButton } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import JobAttachments from "./Attachments/JobAttachments";
import CancelJob from "./Cancel Job/CancelJob";
import JobDescription from "./Description/JobDescription";
import JobInvoice from "./Job Invoice/JobInvoice";
import "./JobPage.css";
import JobPhotos from "./Photos/JobPhotos";
import PrivateJobNotes from "./Private Notes/PrivateJobNotes";
import ScheduleJob from "./Scedule Job/ScheduleJob";
import TrackJob from "./Track Job/TrackJob";
import WorkComplete from "./Work Complete/WorkComplete";

function JobPage() {
  // hooks
  const { jobId } = useParams();
  const navigate = useNavigate();

  // state variables
  const [state, setState] = React.useState({
    actions: true,
    title: "",
    type: "",
    urgency: "",
    job_property: "",
    job_space: "",
  });

  // useEffect Fetch Call
  React.useEffect(() => {
    async function handleFetchCall() {
      const docRef = doc(db, "job_requests", jobId);
      const docData = await getDoc(docRef);

      if (docData.exists) {
        const { job_title, job_type, job_urgency, job_property, job_space } =
          docData.data();

        setState((state) => ({
          ...state,
          title: job_title ? job_title : "",
          type: job_type ? job_type : "",
          urgency: job_urgency ? job_urgency : "",
          job_property: job_property ? job_property : "",

          job_space: job_space ? job_space : "",
        }));
      } else {
        return;
      }
    }

    handleFetchCall();
  }, [jobId]);

  return (
    <div className="JobPage">
      <div className="quickInfo">
        <div className="info">
          <div className="title">{state.title}</div>
          <div className="subtitle">{`${state.type} | ${state.urgency}`}</div>
        </div>
      </div>

      <div className="content">
        <JobPhotos />
        <JobDescription />
        <PrivateJobNotes />
        <JobAttachments />

        <div
          className={
            state.actions ? "actions actionsHidden" : "actions actionsShown"
          }
        >
          {/* side actions */}
          <IconButton
            style={{ color: "rgb(18, 100, 100)" }}
            onClick={(e) => {
              navigate(`/properties/${state.job_property}`);
            }}
          >
            <House fontSize="large" />{" "}
          </IconButton>
          <IconButton
            style={{ color: "rgb(18, 100, 100)" }}
            onClick={(e) => {
              state.job_space !== '' &&
              navigate(`/properties/${state.job_property}`);
            }}
          >
            <MeetingRoom fontSize="large" />{" "}
          </IconButton>
        <TrackJob />
          <JobInvoice />
          <WorkComplete />
          <ScheduleJob />
          <CancelJob />
        </div>

        <Fab
          onClick={(e) => {
            setState((state) => ({ ...state, actions: !state.actions }));
          }}
          className="fab"
          style={{ color: "white", backgroundColor: "rgb(18, 100, 100)" }}
        >
          {" "}
          <ArrowBackIos
            style={{
              transform: state.actions ? "rotate(90deg)" : "rotate(270deg)",
              transition: "all 0.5s linear",
            }}
          />
        </Fab>
      </div>
    </div>
  );
}

export default JobPage;
