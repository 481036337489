import { Card } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import './JobPhotos.css'
import {doc, getDoc} from 'firebase/firestore'
import {db} from '../../../firebase'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'

function JobPhotos() {

    // hooks 
    const {jobId} = useParams(); 

    // state variables
    const [state, setState] = React.useState({
        images: [],
        index: 0,    
    }); 

    // useEffect fetch call 
    React.useEffect(() => {
      async function handleFetchCall () {
        const docRef = doc(db, 'job_requests', jobId); 
        const docData = await getDoc(docRef); 

        if (docData.exists && docData.data().job_images) {
            setState((state) => ({
                ...state, 
                images: docData.data().job_images, 
            }))

        } else {
            return;
        }

      }

      handleFetchCall(); 
    }, [jobId])
    

  return (
    <Card className='JobPhotos'>
        <div className='title'>Photos</div>

        <div className='parent'>
        <div
          className="imgleft"
          onClick={(e) => {
            if (state.index === 0) {
              return;
            } else {
              setState((state) => ({
                ...state,
                index: state.index - 1,
              }));
            }
          }}
        >
          <ArrowBackIos color="inherit" sx={{height: '20px'}} />
        </div>
        <div
          className="imgright"
          onClick={(e) => {
            if (state.index === state.images.length - 1) {
              return;
            } else {
              setState((state) => ({
                ...state,
                index: state.index + 1,
              }));
            }
          }}
        >
          <ArrowForwardIos color="inherit" />
        </div>

            <img className='image' src={state.images && state.images[state.index] && state.images[state.index].url} alt=''/>



        </div>


    </Card>
  )
}

export default JobPhotos