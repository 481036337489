import { History } from '@mui/icons-material'
import { Dialog, IconButton } from '@mui/material'
import React from 'react'
import TrackingDialogContent from './JobTrackingDialogContent'

function TrackJob() {

    const [state, setState] = React.useState({
        open: false,
    })

    function DialogWindow () {
        return (
            <Dialog fullWidth maxWidth='sm' open={state.open} onClose={((e) => {setState((state) => ({...state, open: false,}))})}>
                <TrackingDialogContent />
            </Dialog>
        )
    }


  return (
   <>
   <DialogWindow />
     <IconButton style={{ color: "rgb(18, 100, 100)" }} onClick={((e) => {setState((state) => ({...state, open: !state.open}))})}>
            <History fontSize="large" />{" "}
          </IconButton>
          </>
  )
}

export default TrackJob