import { Search } from '@mui/icons-material'
import { Avatar, Card, IconButton, InputAdornment, ListItem, ListItemAvatar, ListItemText, TextField } from '@mui/material'
import React from 'react'
import './ClientProperties.css'
import { collection, getDocs, query, where } from "firebase/firestore";
import { auth, db } from '../../../firebase';
import { useNavigate } from 'react-router-dom';


function ClientProperties() {

    // hooks 
    const navigate = useNavigate(); 


    // state variables
    const [state, setState] = React.useState({
        loading: false, 
        properties: [],
        search: false, 
        query: '', 
    })

    // UseEffect to fetch values

    React.useEffect(() => {
      async function handleFetch () {
        const q = query(collection(db, 'properties'), where('tradesman', 'array-contains', auth.currentUser.uid)); 
        const results = await getDocs(q); 

        if (results.empty) {
            return; 
        } else {
            setState((state) => ({
                ...state, 
                properties: results.docs.map((doc) => ({...doc.data(), id: doc.id}))
            }))
        }

      }

      handleFetch()
    }, [])
    




    async function handleSearch () {

        if (state.query === '') {
            setState((state) => ({
                ...state, 
                search: false,
            }))
        }

        const q = query(collection(db, 'properties'), where('tradesman', 'array-contains', auth.currentUser.uid)); 
        const results = await getDocs(q); 

        if (results.empty) {
            setState((state) => ({
                ...state, 
                search: false,
            }))
        } else {

const properties = results.docs.map((doc) => ({...doc.data(), id: doc.id})); 
        const filtered = properties.filter((doc) => `${doc.display_name}, ${doc.address_line_1}`.toLowerCase().includes(state.query.toLowerCase())); 

          
 setState((state) => ({
            ...state, 
            properties: filtered, 
            loading: false,
            search: false,
        }))

        }

     


       
    }

  return (
    <div>

        <Card className='ClientProperties'> 

        {state.search && <div className='search'> 


        <div className='searchBar'>
            <TextField value={state.query} onChange={((e) => {setState((state) => ({...state, query: e.target.value}))})} label={'search'} placeholder='Search...' fullWidth  onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={((e) => {
                            handleSearch()
                          })}
                          style={{ color: "rgb(18, 100, 100)" }}
                        >
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}/>

        </div>
            
            </div>}


        <div className='title'>Client Properties</div>

        <div className='list'>

            {state.properties.map((property) => (

                 <ListItem key={property.id} button onClick={((e) => {navigate(`/properties/${property.id}`)})}> 
                <ListItemAvatar>
                    <Avatar alt='' src={property.display_image && property.display_image}/>
                </ListItemAvatar>
                <ListItemText primary={property.display_name} secondary={`${property.address_line_1}, ${property.address_city}`}/>
            </ListItem>
            ))}

           



            <IconButton style={{color:'rgb(18, 100, 100)'}} className='IconButton' onClick={((e) => {setState((state) => ({...state, search: !state.search}))})}>
                <Search />
                </IconButton> 
            </div>

        </Card>


    </div>
  )
}

export default ClientProperties