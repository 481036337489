import { FileCopy } from '@mui/icons-material'
import { Dialog, IconButton } from '@mui/material'
import React from 'react'
import InvoiceDialog from './InvoiceDialog'

function JobInvoice() {

    const [state, setState] = React.useState({
        open: false,
    })


    function DialogWindow () {
        return(
            <Dialog open={state.open} fullWidth maxWidth={'sm'} onClose={((e) => {setState((state) => ({...state, open: false,}))})}>
                <InvoiceDialog />
            </Dialog>
        )
    }

  return (
    <>
    <DialogWindow />
    <IconButton onClick={((e) => {setState((state) => ({...state, open: !state.open}))})} style={{ color: "rgb(18, 100, 100)" }}>
    <FileCopy fontSize="large" />{" "}
  </IconButton></>
  )
}

export default JobInvoice