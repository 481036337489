import { Cancel, CheckCircle } from "@mui/icons-material";
import { Dialog, IconButton } from "@mui/material";
import React from "react";
import CancelJobDialogContent from "./DialogContent";

function CancelJob() {
  const [state, setState] = React.useState({
    open: false,
  });

  function DialogWindow() {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={state.open}
        onClose={(e) => {
          setState((state) => ({ ...state, open: false }));
        }}
      >
        <CancelJobDialogContent />
      </Dialog>
    );
  }

  return (
    <>
      <DialogWindow />

      <IconButton
        onClick={(e) => {
          setState((state) => ({ ...state, open: !state.open }));
        }}
        style={{ color: "rgb(18, 100, 100)" }}
      >
        <Cancel fontSize="large" />{" "}
      </IconButton>
    </>
  );
}

export default CancelJob;
