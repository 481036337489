import { Assignment, Search } from "@mui/icons-material";
import { Card, IconButton, InputAdornment, ListItem, ListItemIcon, ListItemText, TextField } from "@mui/material";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import React from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../../firebase";
import "./InvoicedJobs.css";

function InvoicedJobs() {
  // state variables
  const [state, setState] = React.useState({
    jobs: [],
    search: false,
    query: '', 
    loading: false,
  });

  // hooks
  const navigate = useNavigate(); 


  // useEffect fetch call
  React.useEffect(() => {
    async function handleFetch() {
      const collectionRef = query(
        collection(
          db,
          "Tradesman",
          auth.currentUser.uid,
          "tradesman_job_requests"
        ),
        where("invoiced", "==", true), 
        limit(25)
      );
      const collectionData = await getDocs(collectionRef);

      if (collectionData.empty) {
        return;
      } else {
        setState((state) => ({
          ...state,
          jobs: collectionData.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          })),
        }));
      }
    }

    handleFetch();
  }, []);

  function handleDateConvert(Propdate) {
    const date = Propdate.toDate().toLocaleDateString();

    return date;
  }


  async function handleSearch() {
    setState((state) => ({ ...state, loading: true }));
    if (state.query === '') {
        setState((state) => ({
            ...state, 
            search: false
        }))

    }
    
        const q = query(
            collection(
              db,
              "Tradesman",
              auth.currentUser.uid,
              "tradesman_job_requests"
            ),
            where("invoiced", "==", true), 
          );
      

    
      const docs = await getDocs(q);

      const filtered = docs.docs.filter((doc) => `${doc.data().job_title} ${doc.data().job_type}`.toLowerCase().includes(state.query.toLowerCase()))



      if (docs.docs) {
        setState((state) => ({
          ...state,
          jobs: filtered.map((doc) => ({ ...doc.data(), id: doc.id })),
          loading: false,
          search: false,
        }));
      } else {
        setState((state) => ({
            ...state, 
            loading: false,
            search: false,
        }))
        return;
      }


  }

  return (
    <Card className="InvoicedJobs">
      <div className="title">Recently Invoiced Jobs</div>

      <div className="list">
      {state.search && (
            <div className="search">
              <div className="textfield">
                <TextField
                  fullWidth
                  value={state.query}
                  onChange={(e) => {
                    setState((state) => ({ ...state, query: e.target.value }));
                  }}
                  placeholder="Search..."
                  label="Search"
                  onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={((e) => {
                            handleSearch()
                          })}
                          style={{ color: "rgb(18, 100, 100)" }}
                        >
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          )}




        {state.jobs.map((job) => (
          <ListItem button key={job.id} onClick={((e) => {navigate(`/jobs/${job.id}`)})}>
            <ListItemIcon>
              <Assignment style={{ color: "rgb(18, 100, 100)" }} />
            </ListItemIcon>
            <ListItemText
              primary={job.job_title}
              secondary={`Requested | ${handleDateConvert(job.created_at)}`}
            />
          </ListItem>
        ))}

<IconButton
            className="searchIcon"
            onClick={(e) => {
              setState((state) => ({ ...state, search: !state.search }));
            }}
          >
            <Search style={{ color: "rgb(18, 100, 100)" }} />
          </IconButton>


      </div>
    </Card>
  );
}

export default InvoicedJobs;
