import { Card } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import "./AssetEventDetails.css";
import { db } from "../../../firebase.js";
import { getDoc, doc } from "firebase/firestore";

function AssetEventDetails() {
  // hooks
  const { propertyId, assetId, eventId } = useParams();

  // state variables
  const [state, setState] = React.useState({
    desc: "",
    tradesman: "",
    expense: "",
    type: "",
    visible: true,
  });

  React.useEffect(() => {
    async function handleFetch() {
      const docPath = doc(
        db,
        "properties",
        propertyId,
        "assets",
        assetId,
        "asset_events",
        eventId
      );
      const docData = await getDoc(docPath);

      if (docData.exists) {
        const { desc, expense_visibility, tradesman, type } = docData.data();

        setState((state) => ({
          ...state,
          desc: desc ? desc : "",
          tradesman: tradesman ? tradesman : "",
          type: type ? type : "",
          visible: expense_visibility ? expense_visibility : false,
        }));

        if (docData.data().expense_visibility) {
          // expense is visible
          const expenseRef = doc(
            db,
            "properties",
            propertyId,
            "assets",
            assetId,
            "asset_events",
            eventId,
            "expense",
            "expense_doc"
          );

          const expenseData = await getDoc(expenseRef);
          setState((state) => ({
            ...state,
            expense: expenseData.data().expense,
          }));
        } else {
          // expense is not visible
          return;
        }
      } else {
        return;
      }
    }

    handleFetch();
  }, [propertyId, assetId, eventId]);

  return (
    <Card className="AssetEventDetails">
      <div className="title">Event Details</div>

      <div className="content">
        <div className="section">
          <div className="title">Description</div>
          {state.desc}
        </div>
        <div className="section">
          <div className="title">Tradesman</div>
          {state.tradesman}
        </div>

        <div className="halves">
          <div className="section" >
            <div className="title">Expense</div>

            <div style={!state.visible ? {filter:'blur(3px)'} : {}}>

                 {`$${state.expense === '' ? '1000' : state.expense}`}
            </div>
           
          </div>
          <div className="section">
            <div className="title">Event Type</div>
            {state.type}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default AssetEventDetails;
