import { Card } from '@mui/material'
import { doc, getDoc } from 'firebase/firestore';
import React from 'react'
import { useParams } from 'react-router-dom';
import { db } from '../../../firebase';
import './PropertyFloorPlan.css'

function PropertyFloorPlan() {

    // hooks
    const {propertyId} = useParams(); 

    // state variables
    const [state, setState] = React.useState({
        url: '', 
    })

    // useEffect to fetch default values

    React.useEffect(() => {
        async function handleFetch () {
            const docRef = doc(db, 'properties', propertyId); 
            const docData = await getDoc(docRef); 

            if (docData.exists) {
                setState((state) => ({
                    ...state,
                    url: docData.data().floor_plan ? docData.data().floor_plan : '',
                }))

            } else {
                return;
            }
        }

        handleFetch(); 
    
    }, [propertyId])
    


  return (
    <Card className='PropertyFloorPlan'>
        <div className='title'>Property Floor Plan</div>

        <div className='floorPlan'  onClick={((e) => {window.open(state.url)})}>

          <img className='floorPlan' src={state.url} alt=''/>

            


    


         
            </div>



    </Card>
  )
}

export default PropertyFloorPlan