import { PushPin } from "@mui/icons-material";
import {
  Card,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./Assets.css";

function Assets({ title, code }) {

    // hooks
    const {propertyId, spaceId} = useParams();
    const navigate = useNavigate();  

    // hooks 
    const [state, setState] = React.useState({
        assets: [], 
    }); 

    React.useEffect(() => {

        async function handleFetch () {
            if (code) {
                const collectionRef = query(collection(db, 'properties', propertyId, 'assets'), where('space', '==', code));
                const docs = await getDocs(collectionRef); 

                if (docs.empty) {
                    return;
                } else {
                    setState((state) => ({
                        assets: docs.docs.map((doc) => ({...doc.data(), id: doc.id}))
                    }))
                }

            } else if (spaceId) {
                const collectionRef = query(collection(db, 'properties', propertyId, 'assets'), where('space', '==', spaceId));
                const docs = await getDocs(collectionRef); 

                if (docs.empty) {
                    return;
                } else {
                    setState((state) => ({
                        assets: docs.docs.map((doc) => ({...doc.data(), id: doc.id}))
                    }))
                }


            } else {
                console.log('ERROR: Failed to fetch assets due to invaild space or asset type ID')
                return; 
            }
        }
         
        handleFetch(); 

      
    }, [propertyId, code, spaceId])
    





  return (
    <Card className="Assets">
      <div className="title">{title && title}</div>

      <div className="list">

        {state.assets.map((doc) => (
             <ListItem dense button key={doc.id} onClick={((e) => {navigate(`/properties/${propertyId}/assets/${doc.id}`)})}>
          <ListItemIcon style={{ color: "rgb(18, 100, 100)" }}>
            <PushPin />
          </ListItemIcon>

          <ListItemText primary={doc.display_name} />
        </ListItem> 
        ))}


      
      </div>
    </Card>
  );
}

export default Assets;
