import { PushPin } from '@mui/icons-material'
import { Card, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { collection, getDocs} from 'firebase/firestore'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { db } from '../../../firebase'
import './SpaceAssets.css'

function SpaceAssets() {

  // hooks 
  const {propertyId, spaceId} = useParams(); 
  const navigate = useNavigate();

  // state variables
  const [state, setState] = React.useState({
    assets: [], 
  })

  // useEffect to fetch assets list 

  React.useEffect(() => {
    async function handleFetch () {
      const collectionRef = collection(db, 'properties', propertyId, 'spaces', spaceId, 'assets');
      const datas = await getDocs(collectionRef); 

      if (datas.empty) {
        return;
      } else {
        setState((state) => ({
          ...state, 
          assets: datas.docs.map((doc) => ({...doc.data(), id: doc.id}))
        }))
      }  
    }
    handleFetch(); 

  }, [propertyId, spaceId])
  


  return (
    <Card className='SpaceAssets'>
        <div className='title'>Assets</div>

        <div className='list'> 

        {state.assets.map((asset) => (

           <ListItem button key={asset.id} onClick={((e) => {navigate(`/properties/${propertyId}/assets/${asset.id}`)})} > 
            <ListItemIcon style={{color:'rgb(18, 100, 100)'}}>
              <PushPin />
            </ListItemIcon>
            <ListItemText primary={asset.display_name}/>
          </ListItem>
        ))}


         
        </div>


    </Card>
  )
}

export default SpaceAssets