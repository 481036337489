import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";
import "./InvoiceDialog.css";
import "react-quill/dist/quill.snow.css";
import paid from "./paid.svg";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase";
import { useParams } from "react-router-dom";
import { Send } from "@mui/icons-material";
import { getFunctions, httpsCallable } from "firebase/functions";

function InvoiceDialog() {
  const { jobId } = useParams();

  const [state, setState] = React.useState({
    deliveryMethod: "Tenack",
    amount: 0.0,
    paid: false,
    title: "",
    message: "",
    errorOpen: false,
    errorMessage: "",
    successMessage: "",
    successOpen: false,
    loading: false,
  });

  const [value, setValue] = React.useState("");
  const [disabled, setDisabled] = React.useState("");

  React.useEffect(() => {
    async function handleFetch() {
      const docPath = doc(db, "invoices", jobId); // define doc path

      const data = await getDoc(docPath);

      if (data.exists) {
        const { amount, deliveryMethod, message, paid, title } = data.data();

        if (data.data().disabled && data.data().disabled === true) {
          setDisabled(true);
        }
        setState((state) => ({
          ...state,
          amount: Number(amount / 100).toFixed(2),
          deliveryMethod,
          paid,
          title,
        }));
        setValue(message);
      } else {
        return;
      }
    }

    handleFetch();
  }, [jobId]);

  async function handleSendInvoice() {
    // set loading to true
    setState((state) => ({
      ...state,
      loading: true,
    }));

    const docPath = doc(db, "invoices", jobId); // define doc path
    console.log(docPath.id);

    // set document in collection

    setDoc(docPath, {
      amount: state.amount,
      sender: auth.currentUser.uid,
      message: value,
      sentDate: serverTimestamp(),
      paid: state.paid,
      deliveryMethod: state.deliveryMethod,
      disabled: false,
    })
      .then((res) => {
        // handle success
        // set loading to false
        setState((state) => ({
          ...state,
          loading: false,
          errorOpen: false,
          errorMessage: "",
          successOpen: true,
          successMessage: "Successfully updated invoice details...",
        }));
      })
      .catch((err) => {
        console.log(auth.currentUser.uid, jobId);
        console.log(err);
        // handle error
        // set loading to false
        setState((state) => ({
          ...state,
          loading: false,
          errorOpen: true,
          errorMessage:
            "Failed to apply changes to invoice... please refresh and try again...",
          successOpen: false,
          successMessage: "",
        }));
      });
  }

  async function handleTenackInvoiceSend() {
    setState((state) => ({
      ...state,
      loading: true,
    }));

    const jobDoc = doc(db, "job_requests", jobId);
    const jobObj = await getDoc(jobDoc);
    const { investor } = jobObj.data();

    const functions = getFunctions();
    const fetchUrl = httpsCallable(functions, "handleTenackCreateInvoice");
    fetchUrl({
      amount: Number(state.amount).toFixed(2) * 100,
      message: value,
      receiver: investor,
      title: state.title,
      jobId,
    })
      .then((res) => {
        if (res.data.success) {
          setState((state) => ({
            ...state,
            loading: false,
            errorOpen: false,
            errorMessage: "",
            successOpen: true,
            successMessage: "Successfully Sent Invoice!",
          }));
        } else {
          console.log(res);
          setState((state) => ({
            ...state,
            loading: false,
            errorOpen: true,
            errorMessage:
              "Oops... an Error Occured Please check all details and reload page...",
            successOpen: false,
            successMessage: "",
          }));
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
          errorOpen: true,
          errorMessage:
            "Oops... an Error Occured Please check all details and reload page...",
          successOpen: false,
          successMessage: "",
        }));
      });
  }

  /* Handle Error and Success SnackBar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (
    <div className="InvoiceDialog">
      <Snackbar
        open={state.successOpen}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      {state.loading && (
        <div className="circularProgress">
          <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
        </div>
      )}

      <img
        src={paid}
        alt="paid badge"
        className="paidBadge"
        style={{ visibility: state.paid ? "visible" : "hidden" }}
      />

      <div className="title">Invoice</div>

      <div className="fields">
        <div className="fullwidth">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Delivery Method
            </InputLabel>
            <Select
              disabled={disabled}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={state.deliveryMethod}
              label="Delivery Method"
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  deliveryMethod: e.target.value,
                }));
              }}
            >
              <MenuItem value={"Tenack"}>Tenack</MenuItem>
              <MenuItem value={"Email"}>Email</MenuItem>
              <MenuItem value={"Post"}>Post</MenuItem>
              <MenuItem value={"In Person"}>In Person</MenuItem>
              <MenuItem value={"Other..."}>Other...</MenuItem>
            </Select>
          </FormControl>
        </div>

        {state.deliveryMethod === "Tenack" && (
          <div className="fullwidth">
            <TextField
              disabled={disabled}
              fullWidth
              label="Invoice Title"
              value={state.title}
              onChange={(e) => {
                setState((state) => ({ ...state, title: e.target.value }));
              }}
            />
          </div>
        )}

        <div className="fullwidth">
          <TextField
            disabled={disabled}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: state.deliveryMethod !== "Tenack" && (
                <InputAdornment position="start">
                  <Checkbox
                    disabled={disabled}
                    checked={state.paid}
                    onChange={(e) => {
                      setState((state) => ({ ...state, paid: !state.paid }));
                    }}
                  />
                  PAID
                </InputAdornment>
              ),
            }}
            fullWidth
            label="Invoice Amount"
            type={"number"}
            value={state.amount}
            onChange={(e) => {
              setState((state) => ({ ...state, amount: e.target.value }));
            }}
          />
        </div>

        {state.deliveryMethod === "Tenack" && (
          <div className="payoutHelper">
            Invoices sent through Tenack allow customers to pay via card using
            Stripe
          </div>
        )}

        <div className="fullwidth">
          <ReactQuill
            readOnly={disabled}
            theme="snow"
            value={value}
            onChange={setValue}
            fullWidth
            className="ArticleEdit_HTML_Field"
          />
        </div>

        <div className="button">
          <Button
            disabled={disabled}
            onClick={(e) => {
              if (state.deliveryMethod === "Tenack") {
                handleTenackInvoiceSend();
              } else {
                handleSendInvoice();
              }
            }}
            style={{
              color: "white",
              backgroundColor: "rgb(18, 100, 100)",
              fontWeight: "bold",
            }}
          >
            {state.deliveryMethod === "Tenack" ? (
              <>
                Send Invoice <Send style={{ marginLeft: 10 }} />
              </>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default InvoiceDialog;
