import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import SpaceAssets from "./Assets/SpaceAssets";
import SpaceComments from "./Comments/SpaceComments";
import SpaceImages from "./Images/SpaceImages";
import "./SpacesPage.css";

function SpacesPage() {
  // hooks
  const { propertyId, spaceId } = useParams();

  // state variables
  const [state, setState] = React.useState({
    space_name: "",
    width: "",
    length: "",
    height: "",
  });

  // useEffect to fetch default values bby

  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(db, "properties", propertyId,  "spaces", spaceId);
      const docData = await getDoc(docRef);

      if (docData.exists) {
        const { display_name, space_width, space_length, space_height } =
          docData.data();
        setState((state) => ({
          ...state,
          space_name: display_name ? display_name : "",
          width: space_width ? space_width : "",
          length: space_length ? space_length : "",
          height: space_height ? space_height : "",
        }));
      } else {
        return;
      }
    }

    handleFetch();
  }, [propertyId, spaceId]);

  return (
    <div className="SpacesPage">
      <div className="quickDetails">
        <div className="details">
          <div className="title">{`${state.space_name}`}</div>
          <div className="subtitle">{`${state.length} X ${state.width} X ${state.height}m`}</div>
        </div>
      </div>

      <div className="firstRow">
        <SpaceImages />
        <SpaceAssets />
      </div>

      <div className="secondRow">
        <SpaceComments />
      </div>
    </div>
  );
}

export default SpacesPage;
