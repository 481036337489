import { doc, getDoc } from 'firebase/firestore'
import React from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../../firebase'
import './AssetPage.css'
import AssetDocuments from './Documents/AssetDocuments'
import AssetHistory from './History/AssetHistory'
import AssetPhotos from './Photos/AssetPhotos'
import ProductDetails from './Product Details/ProductDetails'

function AssetPage() {

    // hooks
    const {propertyId, assetId} = useParams(); 

    // state varaibles
    const [state, setState] = React.useState({
        title: '', 
        warranty_amount: '', 
        warrranty_period: '', 
        installation_date: '', 
    })

    // useEffect fetch call 
    React.useEffect(() => {
        async function handleFetch () {
            const docRef = doc(db, 'properties', propertyId, 'assets', assetId); 
            const docData = await getDoc(docRef); 

            if (docData.exists) {

                const {display_name, warranty_amount, warrranty_period, installed} = docData.data(); 

                setState((state) => ({
                    ...state, 
                    title: display_name ? display_name : '', 
                    warranty_amount: warranty_amount ? warranty_amount : '', 
                    warrranty_period: warrranty_period ? warrranty_period : '', 
                    installation_date: installed ? installed : '', 
                }))
            } else {
                return 
            }

        }
        handleFetch(); 
    
    }, [propertyId, assetId])
    


  return (
    <div className='AssetPage'>

        <div className='details'>
            <div className='DetailsTitle'>{`${state.title}`}</div> 
            <div className='DetailsSubtitle'> {`${state.warranty_amount} ${state.warrranty_period} warranty`}</div>
            <div className='DetailsSubtitle'> {`Installed ${state.installation_date}`}</div>
        </div>



        <div className='left'>
            <ProductDetails/>
            <AssetHistory />


        </div>
        <div className='right'> 
        <AssetPhotos />
        <AssetDocuments />

</div>


    </div>
  )
}

export default AssetPage