import { Check } from '@mui/icons-material'
import { Alert, Button, Snackbar } from '@mui/material'
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import React from 'react'
import { useParams } from 'react-router-dom'
import { auth, db } from '../../../firebase'
import './DialogContent.css'

function WorkCompleteDialogContent() {
    

    const {jobId} = useParams(); 


    const [state, setState] = React.useState({
        errorMessage: '', 
        errorOpen: false, 
        successOpen: '', 
        successMessage: '',
    })


    async function handleMarkComplete () {
        const docPath = doc(db, 'Tradesman', auth.currentUser.uid, 'tradesman_job_requests', jobId)

        updateDoc(docPath, {
            completed_at: serverTimestamp(),
        }).then((res) => {
            setState((state) => ({
                ...state, 
                errorOpen: false, 
                errorMessage: '', 
                successMessage: 'Successfully marked job complete...', 
                successOpen: true, 
            }))

        }).catch((err) => {
            setState((state) => ({
                ...state, 
                errorOpen: true, 
                errorMessage: 'Failed to mark job complete...', 
                successMessage: '', 
                successOpen: false, 
            }))


        })

      
    }




        /* Handle Error and Success SnackBar */
        const handleClose = (event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setState((state) => ({
              ...state,
              successOpen: false,
              successMessage: "",
              errorMessage: "",
              errorOpen: false,
            }));
          };
        
          const [vertical] = React.useState("bottom");
          const [horizontal] = React.useState("right");

          

  return (
    <div className='DialogContent'>

<Snackbar
          open={state.successOpen}
          autoHideDuration={1500}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleClose} severity="success">
            {state.successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={state.errorOpen}
          autoHideDuration={1500}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleClose} severity="info">
            {state.errorMessage}
          </Alert>
        </Snackbar>



        <div className='title'>Are you sure you're ready to mark this job complete? this means you've finished the work at the property</div>

        <div className='subtitle'>this action cannot be reversed.</div>
        

        <div className='buttons'>

            <Button style={{color:'black', backgroundColor:'lightgray'}} onClick={((e) => {window.location.reload()})}> 
                Cancel 
            </Button>


            <Button onClick={((e) => {handleMarkComplete()})} style={{color:'white', backgroundColor:'rgb(18, 100, 100)'}}> 
               <Check />  Mark Complete
            </Button>



        </div>
    </div>
  )
}

export default WorkCompleteDialogContent