import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Card } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./SpaceImages.css";

function SpaceImages() {
  // hooks
  const { propertyId, spaceId } = useParams();

  // state variables

  const [state, setState] = React.useState({
    index: 0,
    images: [],
  });

  // useEffect Snippet

  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(db, "properties", propertyId, "spaces", spaceId);
      const docData = await getDoc(docRef);

      if (docData.exists) {
        setState((state) => ({
          ...state,
          images: docData.data().space_images
            ? docData.data().space_images
            : [],
        }));
      } else {
        return;
      }
    }
    handleFetch();
  }, [propertyId, spaceId]);

  return (
    <Card className="SpaceImages">
      <div className="title">Images</div>

      <div className="content">
        <div
          className="left"
          onClick={(e) => {
            if (state.index === 0) {
              return;
            } else {
              setState((state) => ({
                ...state,
                index: state.index - 1,
              }));
            }
          }}
        >
          <ArrowBackIos color="inherit" />
        </div>
        <div
          className="right"
          onClick={(e) => {
            if (state.index === state.images.length - 1) {
              return;
            } else {
              setState((state) => ({
                ...state,
                index: state.index + 1,
              }));
            }
          }}
        >
          <ArrowForwardIos color="inherit" />
        </div>

        <img
          className="img"
          alt=""
          src={
            state.images &&
            state.images[state.index] &&
            state.images[state.index].url
          }
        />
      </div>
    </Card>
  );
}

export default SpaceImages;
