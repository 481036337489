import { doc, getDoc } from 'firebase/firestore'
import React from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../../firebase'
import './AssetHistoryPage.css'
import AssetEventAttachments from './Attachments/AssetEventAttachments'
import AssetEventDetails from './Details/AssetEventDetails'

function AssetHistoryPage() {

    // hooks 
    const {propertyId, assetId, eventId} = useParams(); 

    const [state, setState] = React.useState({
        title: '', 
        date: '', 
    }); 

    React.useEffect(() => {
      async function handleFetch () {
        const docRef = doc(db, 'properties', propertyId, 'assets', assetId, 'asset_events', eventId); 
        const docData = await getDoc(docRef); 
        if (docData.exists) {
            const {title, date} = docData.data(); 

            setState((state) => ({
                ...state, 
                title: title ? title : '', 
                date: date ? date : '', 
            }))

        } else {
            return; 
        }

      }

      handleFetch(); 
    }, [propertyId, assetId, eventId])
    


  return (
    <div className='AssetHistoryPage'>

        <div className='details'>
            <div className='title'>{state.title}</div>
            <div className='subtitle'>{state.date}</div>
        </div>

        <div className='content'>

            {/* details */}
            <AssetEventDetails />

            {/* Attachments */}
            <AssetEventAttachments />



        </div>
        


    </div>
  )
}

export default AssetHistoryPage