import { Email, Phone } from "@mui/icons-material";
import { Alert, Avatar, Button, CircularProgress, IconButton, Snackbar } from "@mui/material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React from "react";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import { auth, db } from "../../../firebase";
import "./ClientDetails.css";

function ClientDetails() {

// hooks
  const { clientId } = useParams();


  // state variables
  const [state, setState] = React.useState({
    address: {
        line_1: '', 
        line_2: '', 
        city: '', 
        state: '', 
        postcode: '', 
        country: '',
    }, 
    first_name: '', 
    last_name: '', 
    email: '', 
    phone: '', 
    successOpen: false,
    successMessage: "",
    errorMessage: "",
    errorOpen: false,
    loading: false,
  })


  const [value, setValue] = React.useState("");



React.useEffect(() => {
  async function handleFetch () {
    const docRef = doc(db, "Tradesman", auth.currentUser.uid, 'clients', clientId); 
    const docData = await getDoc(docRef); 

    if (docData.data()) {
      console.log(docData.data())
      setValue(docData.data().notes ? docData.data().notes : '')
    } else {
      return; 
    }
  }

  handleFetch()
  }, [clientId])
  



  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(db, "Property_Investor", clientId);
      const docData = await getDoc(docRef);

      if (docData) {
        console.log(docData.data())

        const {address_line_1, address_line_2, address_city, address_state, address_postcode, address_country, first_name, last_name, contact_email, contact_phone} = docData.data(); 

        setState((state) => ({
            address: {
                line_1: address_line_1 ? address_line_1 : '', 
                line_2: address_line_2 ? address_line_2 : '', 
                city: address_city ? address_city : '', 
                state: address_state ? address_state : '', 
                postcode: address_postcode ? address_postcode : '', 
                country: address_country ? address_country : '',
            }, 
            first_name: first_name ? first_name : '', 
            last_name: last_name ? last_name : '', 
            email: contact_email ? contact_email : '', 
            phone: contact_phone ? contact_phone : '', 

        }))
      } else {
        return; 
      }


    }

    handleFetch();
  }, [clientId]);


  async function handleNoteSave () { 

    setState((state) => ({
      ...state, 
      loading: true, 
    }))
 
  const docRef = doc(db, "Tradesman", auth.currentUser.uid, 'clients', clientId); 

  await updateDoc(docRef, {
    notes: value
  }).then((res) => {
    setState((state) => ({
      ...state, 
      loading: false, 
      successOpen: true, 
      successMessage: 'Successfully saved notes...', 
      errorOpen: false, 
      errorMessage: ''
    }))

  }).catch((err) => {
    setState((state) => ({
      ...state, 
      loading: false, 
      successOpen: false, 
      successMessage: '', 
      errorOpen: true, 
      errorMessage: 'Failed to update notes...'
    }))
  })

  


  }




     /* Handle Error and Success SnackBar */
     const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setState((state) => ({
        ...state,
        successOpen: false,
        successMessage: "",
        errorMessage: "",
        errorOpen: false,
      }));
    };
  
    const [vertical] = React.useState("bottom");
    const [horizontal] = React.useState("right");



  return (
    <div className="ClientDetails">
          {state.loading && <div className="loading">   
          <CircularProgress style={{color:'rgb(18, 100, 100)'}}/> 

        </div>}
        <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <hr className="hr"/>

      <Avatar sx={{height:'60px', width:'60px'}} className="avatar"/>

      <div className="ClientName">{`${state.first_name} ${state.last_name}`}</div>

      <ReactQuill
            theme="snow"
            value={value}
            onChange={setValue}
            fullWidth
            className="HTMLSection"
          />

          <div className="saveDiv">
            <Button onClick={((e) => {handleNoteSave()})} style={{color:'rgb(18, 100, 100)', fontWeight:'bold'}}>
              SAVE
            </Button>
          </div>

      <div className="footer">
        <div className="address">
          {`${state.address.line_1}, ${`${state.address.line_2}`.length > 3 ? state.address.line_2 + ',' : ''} ${state.address.city}, ${state.address.postcode}`}
        </div>
        <div className="ContactButtons">
          <a href={`tel:${state.phone}`}>
               <IconButton style={{backgroundColor:'rgb(18, 100, 100)', color:' white'}} size='small'>
            <Phone />
          </IconButton>
          </a>
          <a href={`mailto:${state.email}`}>
          <IconButton style={{backgroundColor:'rgb(18, 100, 100)', color:' white'}} size='small'>
            <Email />
          </IconButton>
          </a>
        </div>
      </div>
     



    </div>
  );
}

export default ClientDetails;
