import { Card } from '@mui/material'
import { doc, getDoc } from 'firebase/firestore';
import React from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../../../firebase';
import './PropertyAccess.css'

function PropertyAccess() {

    // hooks
    const {propertyId} = useParams(); 

    // state variables
    const [state, setState] = React.useState({
        image: '', 
        access_notes: '', 
    })


    // useEffect fetch call 

    React.useEffect(() => {
        async function handleFetch () {
            const docRef = doc(db, 'properties', propertyId); 
            const docData = await getDoc(docRef); 

            if (docData.exists) {

                const {display_image, property_notes} = docData.data(); 

                setState((state) => ({
                    image: display_image ? display_image : '', 
                    access_notes: property_notes ? property_notes : '', 
                }))

            } else {
                return; 
            }

        }

        handleFetch(); 
    
    }, [propertyId])
    


  return (
    <Card className='PropertyAccess'>
        <div className='title'>Property Access</div>

        <div className='imgSection'>
            <img className='img' alt='' src={state.image}/>
        </div>
        <div className='message'>{state.access_notes}</div>


        
    </Card>
  )
}

export default PropertyAccess