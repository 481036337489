import { Avatar, Card, ListItem, ListItemAvatar, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import "./AssetEventAttachments.css";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { PictureAsPdf } from "@mui/icons-material";

function AssetEventAttachments() {
  // hooks
  const { propertyId, assetId, eventId } = useParams();

  // state variables
  const [state, setState] = React.useState({
    files: [],
  });

  React.useEffect(() => {
    async function handleFetch() {
      const pdfRef = query(
        collection(
          db,
          "properties",
          propertyId,
          "assets",
          assetId,
          "files_pdfs"
        ),
        where("visible", "==", true), 
        where('event_id', '==', eventId)
      );
      const imgRef = query(
        collection(
          db,
          "properties",
          propertyId,
          "assets",
          assetId,
          "files_images"
        ),
        where("visible", "==", true), 
        where('event_id', '==', eventId)
      );

      const pdfData = await getDocs(pdfRef);
      const imgData = await getDocs(imgRef);

      if (pdfData.empty && imgData.empty) {
        return;
      } else {
        const pdfArr = pdfData.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        const imgArr = imgData.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        const files = imgArr.concat(pdfArr);

        setState((state) => ({
          ...state,
          files: files,
        }));
      }
    }

    handleFetch();
  }, [propertyId, assetId, eventId]);

  return (
    <Card className="AssetEventAttachments">
      <div className="title">Attachments</div>

      <div className="list">

        {state.files.map((file) => (

          <ListItem key={file.id} button onClick={((e) => {window.open(file.url)})}> 

          {file.type === 'application/pdf' ? 
          <ListItemIcon style={{color:'rgb(18, 100, 100)'}}>
            <PictureAsPdf/>
          </ListItemIcon>
          :
          <ListItemAvatar>
            <Avatar src={file.url} alt={file.name}/>
          </ListItemAvatar>
          
        
        }
            <ListItemText primary={file.name}/>
          </ListItem>
        ))}


        {state.files.length === 0 && (
          <div className="noFiles">
            This event has no visible files available...
          </div>
        )}
      </div>
    </Card>
  );
}

export default AssetEventAttachments;
