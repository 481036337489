import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./Components/Login/Login";
import SignUp from "./Components/Sign Up/SignUp";
import ResetPassword from "./Components/Reset/ResetPassword";
import Dashboard from "./Components/Dashboard/Dashboard";
import Header from "./Components/Header/Header";
import { AuthProvider, useAuth } from "./Components/Contexts/AuthContext";
import PropertiesPage from "./Components/Properties Page/PropertiesPage";
import SpacesPage from "./Components/Spaces Page/SpacesPage";
import AssetPage from "./Components/Assets Page/AssetPage";
import AssetHistoryPage from "./Components/Asset History Page/AssetHistoryPage";
import JobPage from "./Components/Job Page/JobPage";
import Settings from "./Components/Settings Page/Settings";
import TradesmanProfilePage from "./Components/Add Tradesman/TradesmanProfilePage";



function App() {

  function PrivateRoute({ children }) {
    const auth = useAuth();

    if (auth.currentUser) {
      return (
        <>
          <Header />
          {children}
        </>
      );
    } else {
      return <Navigate to="/login" />;
    }
  }




  return (
    <div className="App">
      <Router>
         <AuthProvider> 
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
          <Route path="/signup" element={<SignUp />} /> 
             <Route path="/reset-password" element={<ResetPassword />} /> 

      <Route
              path="/home"
              element={
                <PrivateRoute>
               <Dashboard />
                </PrivateRoute>
              }
            />
               <Route
              path="/dashboard"
              element={
                <PrivateRoute>
               <Dashboard />
                </PrivateRoute>
              }
            />
              <Route
              path="/dashboard/client/:clientId"
              element={
                <PrivateRoute>
               <Dashboard />
                </PrivateRoute>
              }
            />

<Route
              path="/properties/:propertyId"
              element={
                <PrivateRoute>
               <PropertiesPage />
                </PrivateRoute>
              }
            />

<Route
              path="/properties/:propertyId/spaces/:spaceId"
              element={
                <PrivateRoute>
               <SpacesPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/properties/:propertyId/assets/:assetId"
              element={
                <PrivateRoute>
               <AssetPage />
                </PrivateRoute>
              }
            />


<Route
              path="/properties/:propertyId/assets/:assetId/events/:eventId"
              element={
                <PrivateRoute>
               <AssetHistoryPage />
                </PrivateRoute>
              }
            />


<Route
              path="/jobs/:jobId"
              element={
                <PrivateRoute>
               <JobPage />
                </PrivateRoute>
              }
            />

<Route
              path="/settings"
              element={
                <PrivateRoute>
               <Settings />
                </PrivateRoute>
              }
            />


<Route
              path="/tradesman_profile/:tradesmanId"
              element={
                <PrivateRoute>
               <TradesmanProfilePage />
                </PrivateRoute>
              }
            />





          </Routes>
         </AuthProvider> 
      </Router>
    </div>
  );
}

export default App;
