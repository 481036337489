import { Alert, Card, Snackbar } from '@mui/material'
import React from 'react'
import ReactQuill from 'react-quill'
import './PrivateJobNotes.css'
import "react-quill/dist/quill.snow.css";
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../../firebase';

function PrivateJobNotes() {

    // hooks 
    const {jobId} = useParams(); 

    // state variables
   const [value, setValue] = React.useState(''); 
   const [state, setState] = React.useState({
    errorOpen: false, 
    errorMessage: '', 
    successOpen: false, 
    successMessage: ''
   })

   // useEffect to fetch default value 
   React.useEffect(() => {
    async function handleFetch() {
        const docRef = doc(db, 'Tradesman', auth.currentUser.uid, 'tradesman_job_requests', jobId); 
        const docData = await getDoc(docRef); 

        if (docData.exists) {
            setValue(docData.data().private_notes ? docData.data().private_notes : ''); 
        } else {
            return;
        }

    }
    handleFetch()
   }, [jobId])


   async function handleDatabaseUpdate () {
    const docRef = doc(db, 'Tradesman', auth.currentUser.uid, 'tradesman_job_requests', jobId); 
    await updateDoc(docRef, {notes: value}).then((res) => {

       setState((state) => ({
        ...state,
        errorOpen: false, 
        errorMessage: '', 
        successOpen: true, 
        successMessage: 'Successfully saved private notes...'
       }))


    }).catch((err) => {
        setState((state) => ({
            ...state,
            errorOpen: true, 
            errorMessage: 'Failed to save private notes...', 
            successOpen: false, 
            successMessage: ''
           }))


    })
   }


        /* Handle Error and Success SnackBar */
        const handleClose = (event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setState((state) => ({
              ...state,
              successOpen: false,
              successMessage: "",
              errorMessage: "",
              errorOpen: false,
            }));
          };
        
          const [vertical] = React.useState("bottom");
          const [horizontal] = React.useState("right");

  return (
    <Card className='PrivateJobNotes'>
             <Snackbar
          open={state.successOpen}
          autoHideDuration={1500}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleClose} severity="success">
            {state.successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={state.errorOpen}
          autoHideDuration={1500}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleClose} severity="info">
            {state.errorMessage}
          </Alert>
        </Snackbar>

        <div className='title'>Notes</div>
        <div className='subtitle'>These notes are visible to the property owner too.</div>

        <ReactQuill
              theme="snow"
            value={value}
              onChange={setValue}
              fullWidth
              className="ArticleEdit_HTML_Field"
              onBlur={() => {
              handleDatabaseUpdate();
              }}
            />


    </Card>
  )
}

export default PrivateJobNotes