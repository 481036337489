import { FileCopy } from "@mui/icons-material";
import { Card, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./AssetDocuments.css";

function AssetDocuments() {
  // hooks
  const { propertyId, assetId } = useParams();

  // state variables
  const [state, setState] = React.useState({
    files: [],
  });

  // useEffect call

  React.useEffect(() => {
    async function handleFetch() {
      const collectionRef = query(
        collection(
          db,
          "properties",
          propertyId,
          "assets",
          assetId,
          "files_pdfs"
        ),
        where("visible", "==", true)
      );
      const docsData = await getDocs(collectionRef);

      if (docsData.empty) {
        return;
      } else {
        setState((state) => ({
          ...state,
          files:  docsData.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
        }));
      }
    }
    handleFetch();
  }, [propertyId, assetId]);

  return (
    <Card className="AssetDocuments">
      <div className="title">Documents</div>
      <div className="list">


{state.files.length === 0 && 
<div className="noFiles">

<div className="text">

  No Visible Documents Available.
  </div>  </div>}

        {state.files.map((file) => (
          <ListItem
            button
            key={file.id}
            onClick={(e) => {
              window.open(file.url);
            }}
          >
            <ListItemIcon style={{ color: "rgb(18, 100, 100)" }}>
              <FileCopy />
            </ListItemIcon>
            <ListItemText primary={file.name} />
          </ListItem>
        ))}
      </div>
    </Card>
  );
}

export default AssetDocuments;
