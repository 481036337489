import { Check } from "@mui/icons-material";
import { Alert, Button, CircularProgress, Snackbar, TextField } from "@mui/material";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../../../firebase";
import "./DialogContent.css";

function CancelJobDialogContent() {
  const { jobId } = useParams();
  const navigate = useNavigate(); 

  const [state, setState] = React.useState({
    errorMessage: "",
    errorOpen: false,
    successOpen: "",
    successMessage: "",
    text: '', 
    loading: false,
  });


  async function handleCancel () {
    const functions = getFunctions();
    const fetchUrl = httpsCallable(functions, "tradesmanCancelJob");

    setState((state) => ({
      ...state, 
      loading: true, 
      successOpen: false, 
      successMessage: '', 
      errorMessage: '', 
      errorOpen: false, 
    }))
    fetchUrl({
      jobId,
    })
      .then((res) => {
          if (res.data.success) {
            setState((state) => ({
            state,
            loading: false, 
            successMessage: res.data.message, 
            successOpen: true, 
            errorMessage: '', 
            errorOpen: false,
            text:''
          }))

          setTimeout(() => {
            navigate('/home')
            
          }, 3000);
          } else {
            setState((state) => ({
            state,
            loading: false, 
            successMessage: '', 
            successOpen: false, 
            errorMessage: 'Failed to successfully cancel job...', 
            errorOpen: true,
          }))
          }
  }).catch((err) => {
    setState((state) => ({
      state,
      loading: false, 
      successMessage: '', 
      successOpen: false, 
      errorMessage: 'Failed to successfully cancel job...', 
      errorOpen: true,
    }))
  })
}

  /* Handle Error and Success SnackBar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };


  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (
    <div className="DialogContent">
      <Snackbar
        open={state.successOpen}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <div className="title">Are you sure you want to cancel this job?</div>

      <div className="subtitle">
        Invoiced jobs cannot be cancelled, cancelling this job cannot be
        reversed.
      </div>

      <div className="actions">
        <TextField
          label=""
          placeholder='TYPE "CANCEL" HERE'
          inputProps={{min: 0, style: { textAlign: 'center' }}}
          size="small"
          style={{ marginBottom: "10px", alignContent:'center' }}
          value={state.text}
          onChange={((e) => {setState((state) => ({...state, text: e.target.value}))})}
          disabled={state.loading}
        />

        <Button
        disabled={state.text === 'CANCEL' && !state.loading ? false : true}
          style={{
            width: "100%",
            backgroundColor: state.text === 'CANCEL' ? "#FF6961" : '#D0D1D2',
            color: "white",
            fontWeight: "bold",
          }}
          onClick={((e) => {handleCancel()})}
        >
         {state.loading ? <CircularProgress style={{color:'white'}} size={25} />: "CANCEL JOB"}
        </Button>
      </div>
    </div>
  );
}

export default CancelJobDialogContent;
