import { Edit } from "@mui/icons-material";
import { Card, IconButton } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./SpaceComments.css";

function SpaceComments() {

  // hooks 
  const {propertyId, spaceId} = useParams(); 

  // state variables
  const [state, setState] = React.useState({
    message: '',
  }); 

  // useEffect fetch call 
  React.useEffect(() => {
    async function handleFetch () {
       const docRef = doc(db, 'properties', propertyId, 'spaces', spaceId); 
       const docData = await getDoc(docRef); 

       if (docData.exists) {
        setState((state) => ({
          ...state, 
          message: docData.data().notes ? docData.data().notes : ''
        }))
       } else {
        return;
       }

    }

    handleFetch(); 
   
  }, [propertyId, spaceId])
  



  return (
    <Card className="SpaceComments">
      <div className="title">Comments</div>
      <div className="message">
       {state.message}
        <IconButton className="iconButton" style={{color:"rgb(18, 100, 100)"}}>
          <Edit />
        </IconButton>
      </div>
    </Card>
  );
}

export default SpaceComments;
