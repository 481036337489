import { AddAPhoto } from "@mui/icons-material";
import {
  Alert,
  Button,
  Card,
  Chip,
  CircularProgress,
  Fab,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import "./PublicProfileEdit.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { auth, db, storage } from "../../../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

function PublicProfileEdit() {
  const [value, setValue] = React.useState("");
  const [changed, setChanged] = React.useState(false);

  const [state, setState] = React.useState({
    trading_name: "",
    address_line_one: "",
    address_line_two: "",
    address_city: "",
    address_state: "",
    address_postcode: "",
    address_country: "",
    logo: "",
    bio: "",
    chips: [],
    newTitle: "",
    loading: false,
    errorOpen: false,
    errorMessage: "",
    successMessage: "",
    successOpen: false,
  });

  React.useEffect(() => {
    setChanged(true);
  }, [state, value]);

  React.useEffect(() => {
    async function handleFetch() {
      const docPath = doc(db, "Tradesman", auth.currentUser.uid);

      getDoc(docPath, {})
        .then((res) => {
          //success

          if (res.exists) {
            const {
              public_name,
              address_city,
              address_line_1,
              address_line_2,
              address_state,
              address_postcode,
              bio,
              chips,
              logo,
            } = res.data();

            setState((state) => ({
              ...state,
              trading_name: public_name ? public_name : "",
              address_line_one: address_line_1 ? address_line_1 : "",
              address_line_two: address_line_2 ? address_line_2 : "",
              address_city: address_city ? address_city : "",
              address_state: address_state ? address_state : "",
              address_postcode: address_postcode ? address_postcode : "",
              chips: chips ? chips : [],
              logo: logo ? logo : "",
            }));
            setValue(bio ? bio : "");

            setTimeout(() => {
                setChanged(false)
                
            }, 500);
          } else {
            return;
          }
        })
        .catch((err) => {
          //error
        });
    }
    handleFetch();
  }, []);

  function handleDelete(chip) {
    console.log(chip);

    let arr = state.chips;
    const index = arr.findIndex((item) => chip === item);

    arr.splice(index, 1);

    setState((state) => ({
      ...state,
      chips: arr,
    }));
  }

  function addChip() {
    let chips = state.chips;
    chips.push(state.newTitle);
    console.log(chips);

    setState((state) => ({
      ...state,
      chips: chips,
      newTitle: "",
    }));
  }

  function handleSave() {
    setState((state) => ({
      ...state,
      loading: true,
    }));

    const docPath = doc(db, "Tradesman", auth.currentUser.uid);

    setDoc(docPath, {
      public_name: state.trading_name,
      address_city: state.address_city,
      address_line_1: state.address_line_one,
      address_line_2: state.address_line_two,
      address_state: state.address_state,
      address_postcode: state.address_postcode,
      bio: value,
      chips: state.chips,
      logo: state.logo,
    }, {merge: true})
      .then((res) => {
        setState((state) => ({
          ...state,
          errorMessage: "",
          errorOpen: false,
          successOpen: true,
          successMessage: "Successfully Saved Changes!",
          loading: false,
        }));
        setTimeout(() => {
            setChanged(false)
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setState((state) => ({
          ...state,
          errorMessage: "Failed to save changes...",
          errorOpen: true,
          successOpen: false,
          successMessage: "",
          loading: false,
        }));
        setTimeout(() => {
            setChanged(false)
        }, 500);
      });
  }

  // function handle profile photo upload
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  function handleInput(item) {
    // handle uploading a profile image
    if (item) {
      const e = item.target.files[0];
      console.log(e);
      // add to image folder in firebase
      const ImageRef = ref(storage, `${auth.currentUser.uid}/images/${e.name}`);

      const uploadTask = uploadBytesResumable(ImageRef, e);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          setState((state) => ({
            ...state,
            loading: true,
          }));
        },
        (error) => {
          // error function ....
          console.log(error);
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Failed to upload image...",
            successMessage: "",
            successOpen: false,
            loading: false,
          }));
        },
        async () => {
          // complete function ....

          const url = await getDownloadURL(ImageRef);
          setState((state) => ({
            ...state,
            errorOpen: false,
            errorMessage: "",
            successMessage: "Successfully Uploaded Image...",
            successOpen: true,
            logo: url,
            loading: false,
          }));
        }
      );
    } else {
      setState((state) => ({
        ...state,
        errorOpen: true,
        errorMessage: "Please Select An Image File...",
        successMessage: "",
        successOpen: false,
        loading: false,
      }));
    }
  }

  /* Handle Error and Success SnackBar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (
    <div className="PublicProfileEdit">
      <Snackbar
        open={state.successOpen}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <Card className="card">
        <Button
          onClick={(e) => {
            handleSave();
          }}
          size="small"
          className="saveButton"
          disabled={!changed}
          style={

            changed ? {
            color: "white",
            backgroundColor: "rgb(18, 100, 100)",
            fontWeight: "bold",
          } : 
            {
                color: "white",
                backgroundColor: "lightgray",
                fontWeight: "bold",
              }
            
            
           }
        >
          SAVE
        </Button>

        {state.loading && (
          <div className="loading">
            <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
          </div>
        )}
        <div className="title">Edit Public Profile</div>

        <div className="fullwidth" style={{ marginBottom: "10px" }}>
          <TextField
            value={state.trading_name}
            onChange={(e) => {
              setState((state) => ({ ...state, trading_name: e.target.value }));
            }}
            label="Trading Name"
            fullWidth
          />
        </div>

        <div className="topsection">
          <div className="left">
            <div className="fullwidth">
              <TextField
                value={state.address_line_one}
                onChange={(e) => {
                  setState((state) => ({
                    ...state,
                    address_line_one: e.target.value,
                  }));
                }}
                label="Address Line One"
                fullWidth
                size="small"
              />
            </div>
            <div className="fullwidth">
              <TextField
                value={state.address_line_two}
                onChange={(e) => {
                  setState((state) => ({
                    ...state,
                    address_line_two: e.target.value,
                  }));
                }}
                label="Address Line Two"
                fullWidth
                size="small"
              />
            </div>

            <div className="halves">
              <div className="half">
                <TextField
                  value={state.address_city}
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      address_city: e.target.value,
                    }));
                  }}
                  label="City"
                  fullWidth
                  size="small"
                />
              </div>
              <div className="half">
                <TextField
                  value={state.address_state}
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      address_state: e.target.value,
                    }));
                  }}
                  label="State"
                  fullWidth
                  size="small"
                />
              </div>
            </div>
            <div className="halves">
              <div className="half">
                <TextField
                  value={state.address_postcode}
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      address_postcode: e.target.value,
                    }));
                  }}
                  label="Postcode"
                  fullWidth
                  size="small"
                />
              </div>
            </div>
          </div>

          <div className="right">
            <div className="image">
              <input
                accept="image/*"
                ref={hiddenFileInput}
                id="icon-button-file"
                type="file"
                onChange={handleInput}
                style={{ display: "none" }}
              />

              <img src={state.logo} alt="logo" className="actualImg" />
              <Fab
                onClick={(e) => {
                  handleClick();
                }}
                className="fab"
                size="small"
                style={{ color: "white", backgroundColor: "rgb(18, 100, 100)" }}
              >
                <AddAPhoto />
              </Fab>
            </div>
          </div>
        </div>

        <div className="subtitle">Bio | Quickly Describe Your Business</div>

        <div className="htmlInput">
          <ReactQuill
            theme="snow"
            value={value}
            onChange={setValue}
            fullWidth
            className="ArticleEdit_HTML_Field"
          />
        </div>

        <div className="subtitle">
          Tags | What Your Business Offers To Customers
        </div>
        <div className="chips">
          {state.chips.map((chip, ind) => (
            <Chip
              key={ind}
              deleteIcon={true}
              onDelete={(e) => {
                handleDelete(chip);
              }}
              style={{
                color: "white",
                backgroundColor: "rgb(18, 100, 100)",
                fontWeight: "bold",
              }}
              label={chip}
              className="chip"
            />
          ))}
        </div>

        <div className="fullwidth">
          <TextField
            label="Chip Title"
            fullWidth
            value={state.newTitle}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                newTitle: e.target.value,
              }));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={(e) => {
                      addChip(state.newTitle);
                    }}
                    style={{
                      color: "white",
                      backgroundColor: "rgb(18, 100, 100)",
                      fontWeight: "bold",
                    }}
                  >
                    ADD
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Card>
    </div>
  );
}

export default PublicProfileEdit;
