import { Alert, Button, CircularProgress, Snackbar, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { auth, db } from "../../../firebase";
import "./SceduleJobDialogContent.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function SceduleJobDialogContent() {
  const { jobId } = useParams();

  const [state, setState] = React.useState({
    date: new Date(),
    errorOpen: false,
    successOpen: false,
    errorMessage: "",
    successMessage: "",
    loading: false, 
  });


  React.useEffect(() => {
    async function handleDefaultValueFetch() {
      const docPath = doc(
        db,
        "Tradesman",
        auth.currentUser.uid,
        "tradesman_job_requests",
        jobId
      );

      const docData = await getDoc(docPath);

      if (docData.exists) {
        const {booking_time} = docData.data();  
        if ( booking_time && booking_time === false) {
          return;
        } else {
          setState((state) => ({
            ...state,
            date: booking_time ? booking_time : new Date(),
          }));
        }
      } else {
        return;
      }
    }

    handleDefaultValueFetch();
  }, [jobId]);


  async function handleSettingBookingTime () {

    console.log(state.date)

    setState((state) => ({
        ...state, 
        loading: true,
    }))

    const docPath = doc(
        db,
        "Tradesman",
        auth.currentUser.uid,
        "tradesman_job_requests",
        jobId
      );


    updateDoc(docPath, {
        booking_time: new Date(state.date), 
        assigned_date: serverTimestamp(), 
    }).then((res) => {

        setState((state) => ({
            ...state, 
            errorOpen: false, 
            errorMessage: '', 
            successOpen: true, 
            successMessage: 'successfully set booking time...', 
            loading: false,
        }))
    }).catch((err) => {
        setState((state) => ({
            ...state, 
            errorOpen: true, 
            errorMessage: 'Failed to set booking time...', 
            successOpen: false, 
            successMessage: '', 
            loading: false,
        }))

    })
    
  }


          /* Handle Error and Success SnackBar */
          const handleClose = (event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setState((state) => ({
              ...state,
              successOpen: false,
              successMessage: "",
              errorMessage: "",
              errorOpen: false,
            }));
          };
        
          const [vertical] = React.useState("bottom");
          const [horizontal] = React.useState("right");




  return (
    <div className="SceduleJobDialogContent">

        {state.loading && <div className="loading"> <CircularProgress style={{color:'rgb(18, 100, 100)'}}/></div>}


            <Snackbar
          open={state.successOpen}
          autoHideDuration={1500}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleClose} severity="success">
            {state.successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={state.errorOpen}
          autoHideDuration={1500}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleClose} severity="info">
            {state.errorMessage}
          </Alert>
        </Snackbar>

      <div className="title">schedule works</div>

      <div className="fields">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            renderInput={(props) => <TextField fullWidth {...props} />}
            label="Booking Time"
            inputFormat="DD/MM/YYYY hh:mm A"
            value={state.date}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                date: e.toISOString(),
              }));
            }}
          />
        </LocalizationProvider>
      </div>

      <div className="buttons">
        <Button
        onClick={((e) => {
            handleSettingBookingTime()
        })}
          style={{
            color: "white",
            fontWeight: "bold",
            backgroundColor: "rgb(18, 100, 100)",
          }}
        >
          Set
        </Button>
      </div>
    </div>
  );
}

export default SceduleJobDialogContent;
