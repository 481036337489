import React from "react";
import { useParams } from "react-router-dom";
import "./PropertiesPage.css";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import PropertyAccess from "./Property Access/PropertyAccess";
import PropertyFloorPlan from "./Floor Plan/PropertyFloorPlan";
import OtherTradesman from "./Other Tradesman/OtherTradesman";
import Spaces from "./Spaces/Spaces";
import Assets from "./Listed Assets/Assets";


function PropertiesPage() {

    // hooks
    const {propertyId} = useParams(); 

    // state variables
    const [state, setState] = React.useState({
        display_name: '', 
        address: {
            line_1: '', 
            line_2: '', 
            city: '', 
            state: '', 
            postcode: '', 
            country: '', 
        }
    })

    // useEffect to handle fetching data to be displayed

    React.useEffect(() => {

        async function handleFetch () {
            const docRef = doc(db, 'properties', propertyId); 
            const docData = await getDoc(docRef); 

            if (docData.exists) {

                const {display_name, address_line_1, address_line_2, address_city, address_state, address_postcode, address_country} = docData.data(); 
                setState((state) => ({
                    display_name: display_name ? display_name : '', 
                    address: {
                        line_1: address_line_1 ? address_line_1 : '', 
                        line_2: address_line_2 ? address_line_2 : '', 
                        city: address_city ? address_city : '', 
                        state: address_state ? address_state : '', 
                        postcode: address_postcode ? address_postcode : '', 
                        country: address_country ? address_country : '', 
                    }


                }))


            } else {
                return;
            }

        }

        handleFetch(); 
     
    }, [propertyId])
    


  return (
    <div className="PropertiesPage">
      <div className="content">
        <div className="item">
          <div className="line">{`${state.display_name}`}</div>
          <div className="line">{`${state.address.line_1}`}</div>
          <div className="line">{`${state.address.line_2}`}</div>
          <div className="line">{`${state.address.city}, ${state.address.state}`}</div>
          <div className="line">{`${state.address.postcode}, ${state.address.country}`}</div>
        </div>


        <div className="left"> 
        <PropertyAccess />
        <Spaces />
        <Assets title={'Energy Assets'} code={'energy'}/>
        <Assets title={'Heating & Cooling Assets'} code={'heating_cooling'}/>

        </div>

        <div className="right"> 
        <PropertyFloorPlan />
        <OtherTradesman />
        <Assets title={'Utilities & Other Assets'} code={'utitities'}/>

</div>



      </div>
    </div>
  );
}

export default PropertiesPage;
