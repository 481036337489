import { CalendarMonth } from "@mui/icons-material";
import { Dialog, IconButton } from "@mui/material";
import React from "react";
import SceduleJobDialogContent from "./SceduleJobDialogContent";

function ScheduleJob() {

    const [state, setState] = React.useState({
        open: false,
    }) 


    function DialogWindow () {
        return (
            <Dialog fullWidth maxWidth='xs' open={state.open} onClose={((e) => {setState((state) => ({...state, open: false,}))})}>
                <SceduleJobDialogContent />
            </Dialog>
        )

    }

  return (
    <>
    <DialogWindow />
      <IconButton onClick={((e) => {setState((state) => ({...state, open: !state.open}))})} style={{ color: "rgb(18, 100, 100)" }}>
        <CalendarMonth fontSize="large" />{" "}
      </IconButton>
    </>
  );
}

export default ScheduleJob;
