import { Avatar, Card, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { collection, getDocs } from 'firebase/firestore';
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { db } from '../../../firebase';
import "./Spaces.css"

function Spaces() {

    // hooks 
    const {propertyId} = useParams(); 
    const navigate = useNavigate(); 

    // state variables
    const [state, setState] = React.useState({
        loading: false, 
        spaces: [], 
    })

    // useEffect fetch call 

    React.useEffect(() => {
        async function handleFetch () {
            const collectionRef = collection(db, 'properties', propertyId, 'spaces'); 
            const docsGotten = await getDocs(collectionRef); 

            if (docsGotten.empty) {
                return;
            } else {
                setState((state) => ({
                    ...state, 
                    spaces: docsGotten.docs.map((doc) => ({...doc.data(), id: doc.id}))
                }))
            }

        }
        handleFetch(); 
    
    }, [propertyId])
    


  return (
    <Card className='Spaces'>
        <div className='title'>
            Spaces
            </div> 
            
            <div className='list'>

                {state.spaces.map((space) => (
  <ListItem dense button key={space} onClick={((e) => {navigate(`/properties/${propertyId}/spaces/${space.id}`)})}>
                    <ListItemAvatar>
                        <Avatar src={space.space_images && space.space_images[0] && space.space_images[0].url && space.space_images[0].url} alt=''/>
                    </ListItemAvatar>
                    <ListItemText primary={space.display_name}/>
                </ListItem>

                ))}

              

            </div>




    </Card>
  )
}

export default Spaces