import { AttachMoney, Lock, Menu, Person } from "@mui/icons-material";
import {
  Card,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import PublicProfileEdit from "./Settings Options/Public Profile/PublicProfileEdit";
import Stripe from "./Settings Options/Stripe";
import AccountDetails from "./Settings Options/Styles/AccountDetails";
import "./Settings.css";

function Settings() {
  const [state, setState] = React.useState({
    open: false,
    option: ''
  });

  const navItems = [
    {
      title: "Public Profile",
      icon: <Person />,
      key: "profile",
    },
    {
      title: "Account Details",
      icon: <Lock />,
      key: "account",
    },
    {
      title: "Stripe Integration",
      icon: <AttachMoney />,
      key: "stripe",
    },
  ];

  return (
    <div className="Settings">
      <div className={state.open ? "sidebar open" : "sidebar closed"}>
        {navItems.map((item) => (
          <ListItem  key={item.key} button onClick={((e) => {setState((state) => ({...state, option: item.key, open: false,}))})}>
            <ListItemIcon style={{ color: "white" }}>{item.icon}</ListItemIcon>

            <ListItemText
              primary={
                <Typography style={{ color: "white", fontWeight: "bold" }}>
                  {item.title}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </div>

      <Card className="toolbar">
        <div>
          <IconButton
            onClick={(e) => {
              setState((state) => ({ ...state, open: !state.open }));
            }}
            style={{ color: "rgb(18, 100, 100)" }}
          >
            <Menu />
          </IconButton>
        </div>

        <div className="title">Settings</div>
      </Card>

      <div className="page">

        {state.option === 'account' && <AccountDetails />}
        {state.option === 'profile' && <PublicProfileEdit />}
        {state.option === 'stripe' && <Stripe />}


      </div>
    </div>
  );
}

export default Settings;
