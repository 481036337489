import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import React from 'react'
import './Dashboard.css'
import Clients from './Clients/Clients'
import ClientProperties from './Client Properties/ClientProperties'
import InProgressJobs from './In Progress Jobs/InProgressJobs'
import NewJobs from './New Jobs/NewJobs'
import InvoiceReady from './Ready For Invoice/InvoiceReady'
import InvoicedJobs from './Invoiced Jobs/InvoicedJobs'
import { doc, getDoc } from 'firebase/firestore'
import { auth, db } from '../../firebase'

function Dashboard() {

  const [state, setState] = React.useState({
    logo: '', 
    public_name: '',
    email: ''
  })

  React.useEffect(() => {
    async function handleFetch () {
      const docPath = doc(db, 'Tradesman', auth.currentUser.uid)
      getDoc(docPath).then((res) => {
        if(res.exists) {

          const {logo, public_name, email} = res.data(); 

          setState((state) => ({
            ...state, 
            logo: logo ? logo : '', 
            public_name: public_name ? public_name : '', 
            email: email ? email : '', 
          }))

        } else {
          return;
        }
      })
    }

    handleFetch(); 
   
  }, [])
  


  return (
    <div className='Dashboard'> 

    <div className='message'>
<div>

   <ListItem >
        <ListItemAvatar>
          <Avatar src={state.logo} alt=''/>
        </ListItemAvatar>
        <ListItemText primary={`Signed In As: ${state.public_name}`} secondary={`${auth.currentUser.email}`}/>
      </ListItem>
</div>
     
    </div>

    <div className='content'>
      <Clients />
      <ClientProperties />
      <InProgressJobs />
      <NewJobs />
      <InvoiceReady />
      <InvoicedJobs />


    </div>


      </div>
  )
}

export default Dashboard