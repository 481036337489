import { Search } from "@mui/icons-material";
import {
  Avatar,
  Card,
  Dialog,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from "@mui/material";
import { collection, getDocs, } from "firebase/firestore";
import React from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../../firebase";
import ClientDetails from "./ClientDetails";
import "./Clients.css";

function Clients() {
  // hooks
  const navigate = useNavigate();

  // state variables
  const [state, setState] = React.useState({
    loading: false,
    clients: [],
    search: false,
    query: "",
    open: false,
  });

  // useEffect to fetch current clients

  React.useEffect(() => {
    async function handleFetchCall() {
      const collectionRef = collection(
        db,
        "Tradesman",
        auth.currentUser.uid,
        "clients"
      );
      const docs = await getDocs(collectionRef);

      if (docs.docs) {
        setState((state) => ({
          ...state,
          clients: docs.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
        }));
      } else {
        return;
      }
    }

    handleFetchCall();
  }, []);

  async function handleSearch() {
    setState((state) => ({ ...state, loading: true }));
    if (state.query === '') {
        setState((state) => ({
            ...state, 
            search: false
        }))

    }
    
        const q = collection( db,
            "Tradesman",
            auth.currentUser.uid,
            "clients")
         
      

    
      const docs = await getDocs(q);

      const filtered = docs.docs.filter((doc) => `${doc.data().first_name} ${doc.data().last_name}`.toLowerCase().includes(state.query.toLowerCase()))



      if (docs.docs) {
        setState((state) => ({
          ...state,
          clients: filtered.map((doc) => ({ ...doc.data(), id: doc.id })),
          loading: false,
          search: false,
        }));
      } else {
        setState((state) => ({
            ...state, 
            loading: false,
            search: false,
        }))
        return;
      }


  }

  function DialogWindow () {
    return(
        <Dialog fullWidth maxWidth='sm' open={state.open} onClose={((e) => {setState((state) => ({...state, open: false,}))})}>
            <ClientDetails/>
        </Dialog>
    )
  }

  return (
    <div>
        <DialogWindow />
      <Card className="Clients">
        <div className="title">Clients</div>

        <div className="list">
          {state.search && (
            <div className="search">
              <div className="textfield">
                <TextField
                  fullWidth
                  value={state.query}
                  onChange={(e) => {
                    setState((state) => ({ ...state, query: e.target.value }));
                  }}
                  placeholder="Search..."
                  label="Search"
                  onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={((e) => {
                            handleSearch()
                          })}
                          style={{ color: "rgb(18, 100, 100)" }}
                        >
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          )}

          {state.clients.map((client) => (
            <ListItem dense button key={client.id} onClick={((e) => {setState((state) => ({...state, open: true}), navigate(`/dashboard/client/${client.id}`))})}>
              <ListItemAvatar>
                <Avatar
                  alt={`${client.first_name} ${client.last_name}`}
                  src={client.image}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${client.first_name} ${client.last_name}`}
                secondary={client.address_city}
                style={{ textTransform: "capitalize" }}
              />
            </ListItem>
          ))}

          <IconButton
            className="searchIcon"
            onClick={(e) => {
              setState((state) => ({ ...state, search: !state.search }));
            }}
          >
            <Search style={{ color: "rgb(18, 100, 100)" }} />
          </IconButton>
        </div>
      </Card>
    </div>
  );
}

export default Clients;
