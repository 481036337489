import { Button, Card, CircularProgress } from '@mui/material'
import { doc, getDoc } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import React from 'react'
import { auth, db } from '../../../firebase'
import './Styles/Stripe.css'

function Stripe() {

    const [state, setState] = React.useState({
        loading: true, 
        onboarding: false, 
    }); 


    React.useEffect(() => {
     async function handleOnboardingCheck () {
        const path = doc(db, 'Tradesman', auth.currentUser.uid, 'Private_Data', 'stripe'); 
        const data = await getDoc(path); 

        console.log(data.exists())

        if (data.exists()) {
            if (data.data().stripe_id) {
                setState((state) => ({
                    ...state, 
                    onboarding: false,
                    loading: false 
                }))

            handleManageAccountLink()
            } else {
                setState((state) => ({
                    ...state, 
                    onboarding: true, 
                    loading: false,
                }))
            }

        } else {
            setState((state) => ({
                ...state, 
                onboarding: true, 
                loading: false,
            }))
        }


     }
     handleOnboardingCheck()
    }, [])
    


    function handleStripeOnboarding () {
        setState((state) => ({
            ...state, 
            loading: true, 
        }))

        const functions = getFunctions(); 
        const fetchUrl = httpsCallable(functions, 'createStripeAccount'); 
        fetchUrl({user: auth.currentUser.uid})
        .then((res) => {

            const data = res.data; 

            window.open(data.url)
            console.log(res)
            setState((state) => ({
                ...state, 
                loading: false,
            }))
        }).catch((err) => {
            setState((state) => ({
                ...state, 
                loading: false,
            }))
        })
    }


    function handleManageAccountLink () {
        setState((state) => ({
            ...state, 
            loading: true, 
        }))

        const functions = getFunctions(); 
        const fetchUrl = httpsCallable(functions, 'createLoginLink'); 
        fetchUrl({user: auth.currentUser.uid})
        .then((res) => {

            const data = res.data; 

          window.location.replace(data.url);

            console.log(res)
            setState((state) => ({
                ...state, 
                loading: false,
            }))
        }).catch((err) => {
            setState((state) => ({
                ...state, 
                loading: false,
            }))
        })
    }


    

  return (
    <div className='StripeDashboard'>

        {state.loading && 
        <div className='loading'> <CircularProgress style={{color:'rgb(18, 100, 100)'}}/> </div>}

        <Card className='card'>
            <div className='title'>Set Up Stripe Integration.</div>
            <div className='helper'>

                Tenack uses Stripe to manage payments follow the link 
                below to set up or manage your Stripe integration to 
                start collecting payments through Tenack.

            </div>

            <div className='buttonSection'>
                <Button disabled={!state.onboarding} onClick={((e) => {handleStripeOnboarding()})} style={{color:'white', backgroundColor:'rgb(18, 100, 100)', padding: 10, fontWeight:'bold'}}>
                    Stripe Onboarding
                </Button>
    
              

            </div>

        </Card>


    </div>
  )
}

export default Stripe