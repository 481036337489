
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Card } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./AssetPhotos.css";

function AssetPhotos() {
  // hooks
  const { propertyId, assetId } = useParams();

  // state variables

  const [state, setState] = React.useState({
    index: 0,
    images: [],
  });

  // useEffect Snippet

  React.useEffect(() => {
    async function handleFetch() {


      const collectionRef = query(collection(db, "properties", propertyId, "assets", assetId, 'files_images'), where('visible', '==', true));
      const docsData = await getDocs(collectionRef);

      if (docsData.empty) {
        return; 
      } else {
        setState((state) => ({
          ...state,
          images: docsData.docs.map((doc) => ({...doc.data(), id: doc.id})),
        }));
      }
    }
    handleFetch();
  }, [propertyId, assetId]);

  return (
    <Card className="AssetPhotos">
      <div className="title">Images</div>

      <div className="content">
        <div
          className="imgleft"
          onClick={(e) => {
            if (state.index === 0) {
              return;
            } else {
              setState((state) => ({
                ...state,
                index: state.index - 1,
              }));
            }
          }}
        >
          <ArrowBackIos color="inherit" sx={{height: '20px'}} />
        </div>
        <div
          className="imgright"
          onClick={(e) => {
            if (state.index === state.images.length - 1) {
              return;
            } else {
              setState((state) => ({
                ...state,
                index: state.index + 1,
              }));
            }
          }}
        >
          <ArrowForwardIos color="inherit" />
        </div>

        <img
          className="img"
          alt=""
          src={
            state.images &&
            state.images[state.index] &&
            state.images[state.index].url
          }
        />
      </div>
    </Card>
  );
}

export default AssetPhotos;
