import { CopyAll } from "@mui/icons-material";
import { Alert, Card, IconButton, Snackbar } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import "./ProductDetails.css";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";

function ProductDetails() {
  // hooks
  const { propertyId, assetId } = useParams();

  // state variables
  const [state, setState] = React.useState({
    product_code: "",
    manufactuer: "",
    serial_number: "",
    asset_type: "",
    successMessage: '', 
    errorMessage: '', 
    successOpen: false, 
    errorOpen: false,
  });

  // useEffect fetch call

  React.useEffect(() => {
    async function fetchCall() {
      const docRef = doc(db, "properties", propertyId, "assets", assetId);
      const docData = await getDoc(docRef);

      if (docData.exists) {
        const { product_code, manufactuer, serial_number, asset_type } =
          docData.data();

        setState((state) => ({
          ...state,
          product_code: product_code ? product_code : "",
          manufactuer: manufactuer ? manufactuer : "",
          serial_number: serial_number ? serial_number : "",
          asset_type: asset_type ? asset_type : "",
        }));
      } else {
        return;
      }
    }

    fetchCall();
  }, [propertyId, assetId]);




  // handle copying item to clipboard 
  function handleClipBoardCopy (value) {

    navigator.clipboard.writeText(value).then((res) => {
      setState((state) => ({
        ...state, 
        errorOpen: false, 
        errorMessage: '', 
        successOpen: true, 
        successMessage: 'Copied to clipboard!', 
      }))

    }).catch((err) => {
      setState((state) => ({
        ...state, 
        errorOpen: true, 
        errorMessage: 'Failed to copy to clipboard...', 
        successOpen: false, 
        successMessage: '', 
      }))



    })

  }; 

        /* Handle Error and Success SnackBar */
        const handleClose = (event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setState((state) => ({
            ...state,
            successOpen: false,
            successMessage: "",
            errorMessage: "",
            errorOpen: false,
          }));
        };
      
        const [vertical] = React.useState("bottom");
        const [horizontal] = React.useState("right");
  


  return (
    <Card className="ProductDetails">

     <Snackbar
          open={state.successOpen}
          autoHideDuration={1500}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleClose} severity="success">
            {state.successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={state.errorOpen}
          autoHideDuration={1500}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleClose} severity="info">
            {state.errorMessage}
          </Alert>
        </Snackbar>



      <div className="title">Product Details</div>

      <div className="items">
        <div className="item">
          <div className="title">Product Code |</div>
          <div className="value">{`${state.product_code.length < 1 ? 'N/A' : state.product_code}`}</div>
          <IconButton size="small" onClick={((e) => {handleClipBoardCopy(state.product_code)})}>
            <CopyAll size="small" />
          </IconButton>
        </div>
        <div className="item">
          <div className="title">Manufactuer |</div>
          <div className="value">{`${state.manufactuer.length < 1 ? 'N/A' : state.manufactuer}`}</div>
          <IconButton size="small" onClick={((e) => {handleClipBoardCopy(state.manufactuer)})}>
            <CopyAll size="small" />
          </IconButton>
        </div>
        <div className="item">
          <div className="title">Serial Number |</div>
          <div className="value">{`${state.serial_number.length < 1 ? 'N/A' : state.serial_number}`}</div>
          <IconButton size="small" onClick={((e) => {handleClipBoardCopy(state.serial_number)})}>
            <CopyAll size="small" />
          </IconButton>
        </div>
        <div className="item">
          <div className="title">Asset Type |</div>
          <div className="value">{`${state.asset_type.length < 1 ? 'N/A' : state.asset_type}`}</div>

          <IconButton size="small" onClick={((e) => {handleClipBoardCopy(state.asset_type)})}>
            <CopyAll size="small" />
          </IconButton>
        </div>
      </div>
    </Card>
  );
}

export default ProductDetails;
